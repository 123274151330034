@font-face {
    font-family: 'Arial';
    src:url("../fonts/Arial.ttf");
}

// ITC Avant Garde Gothic Pro Bold
$title-font: itc-avant-garde-gothic-pro, sans-serif;
$title-bold: 700;
$title-medium: 500;
$title-light: 300;

$body-font: 'Arial';


$color-blue: #00B1B1;
$color-grey: #50687B;

:root {
	--font-small: 15px;

	@media(min-width:768px){
		--font-small:16px;
	}
	@media(min-width:992px){
		--font-small:17px;
	}
}

.container-fluid {
	max-width: 1440px;
}

p, li {
	color: $color-grey;
	font-size: var(--font-small);
	font-family: 'Arial';
	line-height: 1.6;
}

ul, ol {
	padding-left: 1rem;
}

a {
	color: $color-blue;
	transition: all 0.3s ease;
	&:hover,
	&:focus {
		color: darken($color-blue, 10%);
	}
}

.graphic-list {
	counter-reset: number;
	padding-left: 4rem;
	li {
		position: relative;
		margin-bottom: 2.5rem;
		padding-left: 0.5rem;
	}
	li::before {
		content: '';
		display: inline-block;
		height: 59px;
		width: 59px;
		position: absolute;
		left: -67px;
		background-image: url('../images/tag-white.svg');
		background-size: contain;
		background-repeat: no-repeat;
		margin-right:0.5em;
		z-index: 1;
	}
	li::after {
		counter-increment: number;
		content: counter(number);
		position: absolute;
		z-index: 2;
		color: $color-blue;
		font-size: 30px;
		left: -2rem;
		top: -5px;
		font-family: $title-font;
		font-weight: $title-medium;
	}
	li::marker {
		color: transparent;
	}
}

.graphic-title {
	padding-left: 4.5rem;
	position: relative;
	margin-bottom: 4rem;
	&::before {
		content: '';
		display: inline-block;
		height: 59px;
		width: 59px;
		position: absolute;
		left: 0;
		top: 0;
		background-image: url('../images/tag-teal.svg');
		background-size: contain;
		background-repeat: no-repeat;
		margin-right:0.5em;
		z-index: 1;
	}
}

.graphic-sub {
	padding-left: 5.5rem;

	li {
		margin-bottom: 1rem;
	}
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header {
	width: 100%;
	height: auto;
	padding: 1.5rem 0;
	background: #fff;

	@media(min-width: 768px){
		padding: 2rem 0;
	}
}

.header__content {
	background: #fff;
	display: flex;
	justify-content: space-between;
}

.header__logo {
	width: 90px;
	@media(min-width:768px){
		width: 155px;
	}
	@media(min-width:992px){
		width: 205px;
	}
	img {
		object-fit: contain;
		width: 100%;
	}
}

.header__auxlogo {
	margin-bottom: -80px;
	z-index: 1;
	width: 135px;
	@media(min-width:768px){
		width: 230px;
	}
	@media(min-width:992px){
		width: 320px;
		margin-bottom: -88px;
	}
}

.feature {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 1.5rem;
}

.feature__wrapper {
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.feature__media {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.feature__toggle {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0.5rem 1rem;
	background-color: $color-blue;
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	// -webkit-box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	// -moz-box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	// box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	transition: all 0.3s ease;
	z-index: 2;
	opacity: 1;

	@media(min-width:768px){
		padding: 1rem 2rem;
	}

	&:hover,
	&:focus {
		background-color: darken($color-blue, 10%);
		color: #fff;
		text-decoration: none;
	}

	&.hide {
		// display: none;
	}
}

.feature__toggleText {
	color: #fff;
	font-family: $title-font;
	font-weight: 400;
	font-size: 12px;
	margin-right: 5px;
	position: relative;

	@media(min-width:768px){
		font-size: 14px;
	}
	@media(min-width:992px){
		font-size: 16px;
	}
}

.feature__svgPlay {
	display: flex;
	justify-content: center;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}
.feature__svgMute {
	display: flex;
	justify-content: flex-start;
	padding-right: 6px;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
		padding-right: 8px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}

.mfp-bg {
    opacity: 1 !important;
    background: #000 !important;
}

.mfp-close {
	background-color: $color-blue !important;
	padding: 0.5rem !important;
	position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    line-height: normal !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 50px !important;
    width: 50px !important;
	opacity: 1 !important;
	transition: all 0.3s ease !important;

	@media(min-width: 992px){
		width: auto !important;
		flex-direction: row-reverse !important;
	}

	&::after {
		content: '';

		@media(min-width: 992px){
			content: 'Close';
			font-family: $title-font;
			font-size: 16px;
			padding-bottom: 5px;
			margin-right: 1rem;
			font-weight: $title-bold;
		}
	}

	&:hover,
	&:focus {
		background-color: darken($color-blue, 10%) !important;
	}
}

.textblock {
	margin-bottom: 1.5rem;
	@media(min-width: 768px){
		margin-bottom: 2rem;
	}
	@media(min-width:992px){
		margin-bottom: 3rem;
	}

	h1 {
		margin-bottom: 2rem;
		color: $color-blue;
		font-size: 32px;
		line-height: 1.3;
		font-family: $title-font;
		font-weight: $title-light;

		@media(min-width: 768px){
			font-size: 40px;
		}
		@media(min-width:992px){
			font-size: 46px;
		}

		strong {
			font-weight: $title-medium;
		}
	}

	h3 {
		 color: $color-blue;
		 line-height: 1.2;
		 margin-bottom: 1rem;
		 font-size: 27px;
		 font-weight: bold;
	}

	h4 {
		font-family: $body-font;
		color: $color-grey;
		font-size: 20px;
		margin-bottom: 1.5rem;
		line-height: 1.4;
		@media(min-width: 768px){
			font-size: 23px;
		}
		@media(min-width:992px){
			font-size: 25px;
		}
	}
}

.imageblock {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 2.5rem;
	@media(min-width: 992px){
		margin-bottom: 3.5rem;
	}
}

.imageblock__media {
	width: 100%;
	height: 250px;
	position: relative;

	@media(min-width: 768px){
		height: 350px;
	}
	@media(min-width: 992px){
		height: 500px;
	}

	&::after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		right: 0;
		bottom: 0;
		border-top: 120px solid transparent;
		border-right: 120px solid white;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.object-top {
		img {
			object-position: top;
		}
	}
}

.imagetext {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 1.5rem;

	@media(min-width: 768px){
		margin-bottom: 3rem;
	}
	@media(min-width: 992px){
		margin-bottom: 5rem;
	}
}

.imagetext__row {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 1.5rem;

	@media(min-width: 768px){
		display: flex;
		grid-template-columns: 50% 50%;
		margin-bottom: 0;

		&:first-of-type {
			grid-auto-flow: dense;
			flex-direction: row-reverse;

			.imagetext__media {
				grid-column: 2/3;
			}
			.imagetext__text {
				grid-column: 1/2;
			}
		}
	}
}

.imagetext__text {
	padding: 1.5rem 2.5rem;
	background: #E7F3F6;
	width: 100%;

	@media(min-width:768px){
		width: 50%;
		flex: 2;
		max-width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&.clip {
		position: relative;
		&::after {
			content: '';
			width: 0;
			height: 0;
			position: absolute;
			right: 0;
			bottom: 0;
			border-top: 70px solid transparent;
			border-right: 70px solid white;
			@media(min-width: 768px){
				border-top: 90px solid transparent;
				border-right: 90px solid white;
			}
			@media(min-width: 992px){
				border-top: 120px solid transparent;
				border-right: 120px solid white;
			}
		}
	}

	h4 {
		color: $color-blue;
		font-family: $body-font;
		margin-bottom: 1.5rem;
	}
}

.imagetext__media {
	width: 100%;
	height: 250px;
	@media(min-width:450px){
		height: 330px;
	}
	@media(min-width:768px){
		height: auto;
		min-height: 100%;
		width: 50%;
		max-width: 50%;
		flex: 1;
	}
	@media(min-width:1700px){
		height: 500px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top;
	}
}

.cta {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 3rem;
	@media(min-width: 992px){
		margin-bottom: 5rem;
	}

	&.__noMargin {
		margin: 0;
	}
}

.cta__intro {
	margin-bottom: 1.5rem;
	@media(min-width: 992px){
		margin-bottom: 3rem;
	}
	strong {
		color: $color-blue;
		font-family: $body-font;
		margin-bottom: 1.5rem;
	}
}

.cta__main {
	a {
		color: $color-grey;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 3px;
			width: 100%;
			background-color: $color-grey;
			transition: all 0.3s ease;
		}

		&:hover,
		&:focus {
			text-decoration: none;
			color: darken($color-grey, 10%);
			&::before {
				width: 85%;
			}
		}
	}

	h2 {
		color: $color-grey;
		font-family: $body-font;
		font-weight: bold;
		font-size: 24px;

		@media(min-width: 992px){
			font-size: 27px;
		}
	}
}



.roadmap {
	width: 100%;
	height: auto;
	position: relative;
	background: #d9efe6;
	padding: 3.5rem 0 1.5rem 0;
	@media(min-width: 992px){
		padding: 3.5rem 0;
	}

	h2 {
		text-align: center;
		margin-bottom: 2.5rem;
		font-family: $title-font;
		font-weight: $title-medium;
		color: $color-blue;
		font-size: 42px;
	}

	h4 {
		font-family: $body-font;
		color: $color-grey;
		font-size: 22px;
		margin-bottom: 2.5rem;
		line-height: 1.14;
	}

	ol {
		margin-bottom: 3rem;
		@media(min-width: 992px){
			margin-bottom: 5rem;
		}
	}
	ul > li::marker {
		color: $color-blue;
	}
}

.roadmap__logo {
	margin: 0 auto 2rem auto;
	width: 135px;
	@media(min-width: 768px){
		width: 170px;
	}
	@media(min-width: 992px){
		width: 200px;
	}
}

.listblock {
	width: 100%;
	height: auto;
	position: relative;
	padding: 1.5rem 0;
	@media(min-width: 992px){
		padding: 3.5rem 0;
	}
	h2 {
		color: #42B084;
		margin-bottom: 2rem;
		font-size: 30px;
		font-family: $title-font;
		font-weight: $title-medium;
		@media(min-width: 768px){
			font-size: 38px;
		}
		@media(min-width: 992px){
			font-size: 44px;
		}
	}

	ul > li {
		color: #50687B;
		margin-bottom: 1rem;
	}
	ul > li::marker {
		color: #42B084;
	}
}

.blockquote {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 5rem;
}
.blockquote__body {
	color: #42B084;
	font-family: $body-font;
	line-height: 1.3;
	font-size: 30px;

	@media(min-width: 992px){
		font-size: 32px;
	}
}